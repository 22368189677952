export const environment = {
  auth0:{
    domain: 'otrleasing.us.auth0.com',
    clientId: 'dXOkc1BL9K6n8bYfqvH3PeWzaGgfRUHI',
    redirect_uri: 'http://localhost:4200/login'
  },
  lease_path: {
    base_uri: 'https://stage.api.otrleasing.com/api/',
    key: 'b3RyTGVzaW5nU2VydmljZVVzZXI6VyEzM1NlcnZpY2VAcHA=',
    env: 'stage'
  }
};
